@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700&display=swap');


@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1200px;
    }
}
body {
    font-family: "Poppins", sans-serif;
    min-height: 100vh;
}
::selection {
    color: rgb(253, 253, 253);
    background: #5948db;
  }
a {
    color: inherit;
    text-decoration: none;
}
.rela{
    position: relative;
}
.fir-maib-r {
    background: linear-gradient(45deg, #1F194E, #29206D);
    padding-top: 78px;
    padding-bottom: 78px;
}
.form-check-input{
    background-color: #ffffff00;
    border-color: #000000;
}
.form-check-input:checked {
    background-color: #ffffff00;
    border-color: #000000;
    filter: brightness(0);
}
.engiht {
    background-color: #fff;
    border-radius: 0 10px 10px 10px;
    padding: 0 20px 25px;
}
.orgin-eror{
    color: rgb(255 63 63);
    padding: 0 13px;
    font-size: 12px;
    margin: 0px;
    position: absolute;
    bottom: -19px;
    font-weight: 600;
}
.enginer-se {
    align-items: start;
    border: 1px solid #d8dee5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 9px 15px;
    position: relative;
    background: white;
}
.enginer-se:hover{
    background: #5948db38;
}
.sdetr2:hover {
    border-color: #ffffff;
}
  .gfyy3 {
    font-size: 12px;
    margin: 0;
  }
  .est354 {
    width: 100%;
    border-top: 1px solid #8a8c8f;
    background-color: white;
}
.weghrt {
    position: absolute;
    top: -10px;
    left: 6px;
    font-size: 12px;
    background: white;
    color: #606060;
    padding: 0 3px;
    font-weight: 400;
    margin: 0;
    border: 1px solid #ffffff;
    border-radius: 3px;
}
.enginer-se:hover .weghrt{
    border: 1px solid #dbd7f7;
}
.edtrdt {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    color: #303030;
}
.seroj {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    margin: 0;
    color: #606060;
    width: 100%;
}
.edtrdt span {
    font-weight: 400;
    font-size: 16px;
}
.asrije{
    width: 100%;
    height: 100%;
    background: #5948DB;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 19px;
    font-weight: 500;
}
.re64e4 .asrije {
    background: #7167B2;
}

.asrije:hover {
    background: #261e65 !important;
    border: 1px solid white;
}

.jkdgt {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #5948DB;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 19px;
    z-index: 999;
    transform: translate(-50%, -50%);
    border: 3px solid white;
}
.jkdgt:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.re64e4 .jkdgt {
    background: #7167B2;
    border: 3px solid #403974;
}
.sfsdt{
    padding-right: 8px;
    padding-left: 8px;
}
.row{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.font-500{
    font-weight: 500;
}
.iutit{
    width: 142px;
}
header {
    padding: 5px;
    border-bottom: 1px solid #c7c8c9;
    position: sticky;
    top: 0;
    background: white;
    z-index: 9999999;
}
.dfgsdg{
    background-color: rgba(0, 0, 0, 0) !important;
}
.nav-link{
    color: #303030;
    font-weight: 600;
}
.dropdown-toggle::after{
    display: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #303030;
    font-weight: 600;
}
li.nav-item {
    margin: 0 9px;
}
.zdujr-pop {
    position: absolute;
    top: 0;
    background: white;
    width: 293px;
    left: 0;
    border: 1px solid #d8dee5;
    border-radius: 9px;
    z-index: 999999;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.sdtst{
    border: none !important;
}
.srihe {
    border-top: 1px solid #d8dee5;
    border-bottom: 1px solid #d8dee5;
    padding: 4px 12px;
    font-size: 13px;
    color: #6a6760;
}
.dhit{
    width: 87%;
}
.asru23{
    color: #6a6760;
}
.form-floating>.form-control-plaintext~.asru23, .form-floating>.form-control:focus~.asru23, .form-floating>.form-control:not(:placeholder-shown)~.asru23, .form-floating>.form-select~.asru23,.form-floating>.form-select:not(:placeholder-shown)~.asru23{
    display: none;
}
.kjdr {
    padding: 8px;
}
.urer{
    margin-right: 12px;
}
.wdtew{
    font-size: 17px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.sdtewt{
    font-size: 13px;
    color: #6f6f6f;
}
.kjdr:hover {
    background: #5948db24;
}
.uge{
    overflow-y: scroll;
    /* scrollbar-width: thin; */
    max-height: 244px;
}
.form-control:focus {
    box-shadow: none;
}
.zdujr-pop1 {
    position: absolute;
    top: 56px;
    background: white;
    width: 310px;
    right: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid #d8dee5;
    border-radius: 9px;
    z-index: 999999;
    overflow: hidden;
}  
.dtstset {
    font-weight: 600;
    font-size: 17px;
    color: #303030;
    margin-bottom: 6px;
}

.srwsrr1 {
    font-size: 10px;
    color: #6B6B6B;
    font-weight: 400;
}
.teiyr {
    border: none;
    border-radius: 5px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #565555;
    font-size: 20px;
    background: #F2F2F2;
}
.udhyt {
    font-size: 14px;
    color: #363636;
    font-weight: 500;
    margin:12px 0;
}
.fdtyr {
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sert4 {
    border: none;
    margin-bottom: 13px;
    font-size: 12px;
    width: 100%;
    border-radius: 7px;
    color: #303030;
    font-weight: 500;
    height: 54px;
}
.sert4:hover {
    border: 1px solid #241D5D;
}
.activrt{
    background:#241D5D;
    color: white;
}
.sttde5 {
    border: none;
    background: #ededed;
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    color: #5948db;
    font-weight: 500;
}
.teiyr:hover {
    background: #d5d1d1;
}
.dtjrrdt{
    padding-top: 30px;
    padding-bottom: 30px;
}
.ghjge3{
    font-size: 14px;
    font-weight: 700;
    color: black;
    margin: 0;
}
.ghjge3 span{
    color: #5948DB;
}
.wri34d{
    font-size: 12px;
}
.dftgdfty {
    background: #5948db;
    color: #ffffff;
    padding: 10px;
}
  .sfs34f {
    background: white;
    padding: 15px;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.wqerq45 {
    background:#F6F4F4;
}
.e5t66 {
    font-size: 15px;
    font-weight: 500;
}
.fdtd3 {
    margin-left: 13px;
    padding-right: 10px;
}
.dtgr6r4 {
    font-size: 11px;
}
.rftw4{
    border-right: 1px solid white;
    border-left: 1px solid white;
}
.ikshri3 {
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
}
.wrs6rr33 {
    height: 250px;
}
.sjr4 {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    width: 100%;
    background: linear-gradient(0deg, #000000e3, transparent);
    padding-bottom: 15px;
}
.srhrw4 {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 24px;
}
.siryh4 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
}
.shru24 {
    color: #E7E7E7;
    font-size: 11px;
    font-weight: 300;
    margin-bottom: 0;
}
.hjgrjw4 {
    font-size: 23px;
    font-weight: 500;
    margin: 0;
}
.dfsdfh {
    font-size: 11px;
    color: #303030;
}
.dfyhr5 {
    font-size: 20px;
    font-weight: 600;
    color: #303030;
}
.dfhuje {
    line-height: 18px;
}
.kdht4 {
    color: white;
    font-size: 32px;
    margin-bottom: 35px;
}
.tabuiy1 {
    color: white;
    width: fit-content;
    border-radius: 10px 10px 0 0;
    padding: 13px 0px;
}
.tabuiy1 img{
   filter: brightness(0) invert(1);
}
.tabuiy1 .dttre4 {
    padding: 0 20px;
    border-bottom: none;
}
.edtr24{
    border-right: 1px solid #9E9E9E;
}
.tabuiy .edtr24{
    border-right:none;
}
.tabuiy {
    color: #5948db;
    background: white;
    padding: 13px 20px 0px 20px;
}
.tabuiy img {

   filter: unset;
}
.jhtudt {
    font-weight: 600;
}
.tabuiy .dttre4 {
    padding: 0 0 13px 0 !important;
    border-bottom: 2px solid #5948DB;
}
.swrs {
    border: 1px solid white;
    width: fit-content;
    border-radius: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ier9u {
    display: none;
}
.srhrw4:hover .ier9u{
    display: inherit;
    transition: 1s;
}
.rt6er6{
    height: 524px;
}

.edrt4{
    border: 1px solid #BABABA;
    color: #5C5C5C;
    background-color: white;
    padding: 20px 35px;
    border-radius: 13px;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 27px;
    margin-right: 15px;
}
.edrt4:hover {
    border: 1px solid #5948db;
    color: #5C5C5C;
    background-color: #5948db30;
}
.dt4ff {
    background-color: #5948DB;
    color: white;
    border: none;
}
.ietr3 {
    color: #5948db;
    margin-bottom: 27px;
    padding: 7px;
    background: #5948db14;
    border-radius: 6px;
}
.jkhdt{
    background: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 1px -2px;
}
.ewtay3 {
    color: #303030;
    font-weight: 500;
}
.ew63ew {
    padding: 10px 0;
    border-bottom: 1px solid #dbdada;
}
.ew63ew:hover .sar3d{
    background-color: #5948DB;
    color: white;
    padding: 15px 45px;
    border-radius: 10px;
    width: fit-content;
    margin: auto;
}
.ew63ew:first-child {
    border-top: 1px solid #dbdada;
}
.ew63ew:hover {
    background: #F5F7FF;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: none;
}

.srtr5e3 {
    font-size: 10px;
    color: #6e6e6e;
    margin: 0;
}
.srtr5e3 span {
    font-weight: 600;
}
.ueyru4 {
    color: #303030;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 18px;
}
.jie5i4 {
    font-size: 13px;
    color: #505050;
    margin-bottom: 4px;
}
.irt56 {
    color: #5948DB;
    font-size: 14px;
}
.faur {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sjrg{
    background: white;
    padding: 14px;
    border-radius: 13px;
    border: 1px solid #E1E1E1;
    margin-bottom: 16px;
}
.asruhe{
    margin: 0;
    color: #303030;
}
.dtgedt {
    background: #5948DB;
    color: white !important;
}
.dtgedt img{
    filter: brightness(0) invert(1);
}
.dtgedt .asruhe{
    color: white;
}
.sewr32{
    font-size: 14px;
    margin: 0 40px;
}
.seir3e {
    color: #303030;
    font-weight: 600;
    margin-bottom: 15px;
}
.srij4e li {
    list-style: none;
}
.srij4e {
    color: #505050;
    padding: 0;
    font-size: 14px;
    line-height: 28px;
}
.srij4e li a:hover{
    text-decoration: underline;
}
.fdktjr {
    color: #505050;
}
.eedt54 {
    text-align: center;
    font-size: 11px;
    color: #505050;
}
.drui5b {
    display: flex;
    margin: auto;
}
.idrhue {
    text-align: center;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 0;
}
.seiry53 {
    padding: 45px;
    background: linear-gradient(180deg, #EEECFD 50%, #5948DB 50%);
}
.jet5 {
    background: white;
    border: 8px solid #5948db;
    padding: 25px;
    border-radius: 22px;
    text-align: center;
}
.isyhe4 {
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    color: #303030;
}
.jkdhtj {
    background: #F6F4FF;
    width: 55%;
    margin: auto;
    padding: 18px;
    margin-top: 17px;
    border-radius: 18px;
}
.et45dct,
.et45dct:focus{
    background: none;
    border: none;
}

.jkdt {
    display: flex;  
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #505050;
    margin-right: 25px;
}
.dtghgsr {
    background: #4D36FF;
    border: none;
    color: white;
    padding: 9px 35px;
    border-radius: 50px;
    font-weight: 300;
}
.otuji4 {
    color: #404040;
    font-size: 10px;
    margin-bottom: 0;
    margin-right: 43px !important;
    text-align: right;
}
.seiry524{
    background-color: #5948DB;
    color: white;
    padding-bottom: 25px;
}
.ihteike {
    padding: 0;
    margin: 0;
    line-height: 33px;
    font-weight: 300;
    height: 142px;
    overflow: hidden;
}
.dri4e {
    display: flex;
    margin-left: auto;
    background: none;
    color: white;
    border: none;
    font-weight: 600;
}
.ihteike li{
    list-style: none;
}
.et5etre {
    border-bottom: 1px solid white;
    margin-bottom: 20px;
    display: flex;
}
.dtet {
    margin: 0;
    width: 25%;
    font-weight: 500;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
    position: relative;
}
.dtet:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 10px;
    height: 75%;
    border-right: 1px solid #ffffff91;
    transform: translate(0, -50%);
}
.dtet:last-child {
    position: inherit;
}

.iehri {
    border-bottom: 2px solid white;
    background: #ffffff52;
}
.dropdown-menu {
    min-width: 50%;
    max-width: 100%;
    left: 50% !important;
    transform: translate(-50%);
}
.dropdown-menu .dropdown-item{
    font-size: 13px;
    padding: 5px 8px !important;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #5948db;
}
.navbar-nav .nav-item {
    position: inherit;
}
ul.dropdown-menu.show {
    border-radius: 13px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
}
.dropdown-header {
    color: black;
    font-size: 17px;
    font-weight: 500;
    padding: 6px 0;
}
.engiht12 {
    padding: 20px;
    background-color: #ffffff00;
}
.sdetr2{
    background-color: #38306D;
    border-color: #38306D;
}
.w42re {
    font-size: 12px;
    background: #38306D;
    color: #DBDBDB;
    font-weight: 300;
    border: 1px solid #ffffff00;
}

.ryr65 {
    color: #DBDBDB;
    font-weight: 300;
}
.ds5t43 {
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
.ds5t43 span{
    font-weight: 300;
}
.engiht12 .form-check-input:checked {
    background-color: #ffffff00;
    border-color: #ffffff;
    color: white;
    filter: brightness(1);
}   
.engiht12 .form-check-input{
    border-color: #ffffff;
}
.re64e4{
    padding-top: 8px;
    padding-bottom: 8px;
}
.iure4{
    background-color: #F8F8FF;
    padding: 20px;
}
.ugsre {
    background: white;
    padding: 24px;
    border-radius: 20px;
}
.udtgr {
    color: #4A4A4A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
}
.et3xt {
    color: #5948DB;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}
.jtr,
.sgr34 .form-check-input,
.sgr34 .form-check label,
.sgr34 {

    cursor: pointer;
}
.form-check-input:focus{
    box-shadow: none;
}
.sdet2{
    color: #424242;
    padding-left: 10px;
}
.sgr34 .form-check{
    font-size: 21px;
    display: flex;
    align-items: end;
    height: 0;
    width: fit-content;
    max-width: 100%;
    position: relative;
}
.sgr34 .form-check label{
    margin-left: 10px;
    color: #4A4A4A;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.sgr34 .form-check-input{
    border: 1px solid #E6E6E6;
}
.sgr34 {
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 1px dashed #ECECEC;
    padding-left: 10px;
    /* position: relative; */
}
.ieht54{
    border-top: 2px solid #d3cfcf;
}
.awrje {
    color: #424242;
    font-size: 12px;
    margin: 0;
}
.jtr{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.jsdt {
    width: 42px;
    height: 42px;
    display: flex;
    filter: brightness(0) invert(0);
    opacity: 0.2;
    justify-content: center;
    align-items: center;
}

.usrge {
    color: #5948DB;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
}
.jgdte{
    color: #424242;
    font-size: 14px;
}
.ujdht {
    width: 100%;
    height: 1px;
    background: #5948DB;
    display: flex;
    align-items: flex-end;
}
.uht::before {
    /* position: absolute; */
    /* content: ''; */
    width: 14px;
    height: 14px;
    background: #5948db;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    border-radius: 50%;
    border: 2px solid #ffffff;
    outline: 1px solid #5948db;
}
.uht {
    width: 80%;
    background: #5948db;
    height: 2px;
    position: relative;
}
.srud {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}
.d5ee3f {
    font-size: 14px;
    color: #565656;
}
.dstye{
    display: none;
}
.kjdgsr {
    background: white;
    border-radius: 18px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    overflow: hidden;
}
.kjer {
    background: #5948DB;
    color: white;
    padding: 10px;
}
.skhrw{
    display: flex;
    justify-content: end;
}
.krt {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 26px;
    justify-content: center;
    margin-right: 18px;
    position: relative;
    width: 26px;
}
.krt:hover{
    background-color: #e1e1e1;
}
.krt:last-child{
    margin: 0;
}
.eithn {
    border-right: 1px solid #e1e1e1ad;
}
.fhyfy {
    width: 21%;
    background-color: white;
}
.wte56 {
    font-size: 16px !important;
}

.foent {
    height: 75px;
    font-size: 15px;
    color: #454545;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #D8D8D8;
    cursor: pointer;
    position: relative;
}
.font-500 {
    font-weight: 500 !important;
}
.foent1 {
    height: 37px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #454545;
    border-bottom: 1px solid #D8D8D8;
    cursor: pointer;
    font-size: 16px;
}
.rate-col:last-child,
.foent1:last-child{
    border-bottom: none;
}
.fhyfy1 {
    width: 79%;
    background-color: white;
    display: flex;
    overflow: hidden;
    scrollbar-width: none;
}
.eithn2 {
    min-width: 20%;
    max-width: 20%;
    border-right: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
}
.rate-col {
    border-bottom: 1px solid #cccccc;
    height: 37px;
    text-align: center;
    font-size: 13px;
    color: #101010;
    cursor: pointer;
    font-weight: 600;
}
.kdti4,
.urhue4{
    display: none;
}
.form-floating>.form-control:not(:placeholder-shown)~label::after{
    background-color: rgb(255, 255, 255);
}
/* width */
::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff; 
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #929292; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #757575; 
  }
  .sttedr{
    display: none;
  }
  .idhte span {
    font-size: 11px;
    font-weight: 500;
}

.idhte {
    font-size: 10px;
    margin: 12px 0;
}
.jiseth {
    background: #5948DB;
    color: white;
    font-weight: 300;
    font-size: 14px;
    padding: 7px 21px;
    border-radius: 12px;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}
.jiseth img {
    margin-left: 12px;
}
.esitj4 {
    margin: 0;
    color: #5948DB;
    text-decoration: underline;
    font-size: 14px;
}
.dstkje {
    background: #F9F9F9;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding: 13px;
    display: flex;
    align-items: center;
    border-radius: 15px;
}
.kheewr {
    display: flex;
    align-items: baseline;
    border-right: 2px solid #C9C9C9;
    padding: 0 25px;
}
.kheewr:last-child{
    border-right: none;
}
.kheewr img {
    margin-right: 10px;
    filter: brightness(0) invert(0);
}
.dst45 {
    font-size: 18px;
    font-weight: 500;
}
.sdtew::before {
    content: '';
    position: absolute;
    width: 82%;
    height: 3px;
    background: #5948db;
    bottom: -13px;
    left: 50%;
    transform: translate(-50%);
    border-radius: 7px;
}
.sdtew {
    color: #5948DB;
    position: relative;
}
.sdtew img{
    filter: unset;
}
.dyhtg4 {
    font-size: 20px;
    font-weight: 600;
}
.srtwe{
    display: none;
}
.lists-all {
    background: white;
    margin-top: 20px;
    border-radius: 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;
    /* overflow: hidden; */
}
.ikrtr {
    margin-bottom: 0;
    color: #505050;
    font-size: 14px;
}
.hftrr {
    border-radius: 50%;
    border: 1px solid #C1C1C1;
    padding: 2px;
    margin-right: 6px;
    width: 32px;
    height: 32px;
}
.jjeisr4 {
    font-size: 12px;
    color: #707070;
    margin-top: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.ioheri{
    color: #505050;
    font-weight: 500;
}
.edjhire {
    color: #383838;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
    position: relative;
}
.usyhrt .edjhire{
    width: fit-content;
}
.kijdte {
    font-size: 13px;
    margin-top: 6px;
    color: #505050;
}
.kijdte span {
    font-size: 10px;
    color: #808080;
    font-weight: 400;
}
.ikdhte {
    color: #606060;
    font-size: 12px;
}
.kiejr {
    margin: 4px 0;
    border-top: 1px dashed #5948DB;
    opacity: 1;
    position: relative;
}
.dort {
    padding: 14px;
}
/* .dort:hover {
    background: #5948db1c;
}  */
.ikhee {
    color: #505050;
    font-weight: 500;
    margin-bottom: 0;
}
.ikhere {
    color: #1F194E;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
.oejrsxr {
    color: #606060;
    font-size: 14px;
}
.idrhts {
    background: #5948DB;
    border: none;
    color: white;
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 21px;
    font-weight: 500;
}
.idrhts:hover {
    background: #271e67;
}
.dstegr4{
    border-left: 1px dashed #c7c7c7;
    width: 20%;
}
.wrerew{
    width: 80%;
}
.et4ft {
    color: #5948DB;
    margin-left: 12px;
}
.jgsr {
    background: #ECEAFF;
    padding: 6px;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 10px;
}
.usyhrt {
    background: #f6f6f7;
    box-shadow: 0 6px 8px -6px #32325d40, 0 1px 3px -1px #0000004d;
    padding: 18px;
    margin-top: -18px;
    border-radius: 0 0 15px 15px;
}
.jda34 {
    background: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 0 0 15px 15px;
}
.dstge3 {
    color: #232323;
    font-size: 18px;
    font-weight: 600;
}
.dt54s span {
    color: #404040;
    font-weight: 500;
}

.dt54s {
    color: #606060;
    font-size: 14px;
    font-weight: 300;
}
.dst342{
    color: #606060;
    font-size: 12px;
}
.dst342 span {
    text-decoration: underline;
    position: relative;
    font-weight: 600;
}
.dsfed4 {
    padding: 10px;
    position: absolute;
    background: white;
    z-index: 99999;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    width: 200px;
    left: 50%;
    transform: translate(-50%);
    display: none;
    margin: 0;
}
.dsfed4:before {
    content: "";
    position: absolute;
    top: -1px;
    left: 49%;
    transform: translate(-50%);
    width: 10px;
    height: 10px;
    background: #ffffff;
    rotate: 45deg;
    z-index: 9;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dedede;
}
.dst342 span:hover .dsfed4 {
    display: block;
}
.jioshr {
    font-size: 12px;
    color: #404040;
    font-weight: 400;
    position: relative;
}
.jioshr span:before {
    position: absolute;
    content: '';
    background: #D9D9D9;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 50%;
    left: -10px;
    transform: translate(0, -50%);
}

.jioshr span {
    color: #707070;
    margin-left: 13px;
    position: relative;
}
.dtges{
    background: #5948DB; 
    width: 14px; 
    height: 14px; 
    border-radius: 24px; 
    display: flex; 
    margin: auto;
}
.destde{
    width: 2px; 
    height: 99px; 
    background: #0000002e; 
    display: flex; 
    margin: auto;
}
.iehte3{
    background: #5948DB; 
    width: 14px; 
    height: 14px; 
    border-radius: 24px; 
    display: flex; 
    margin: auto;
}
.jgsr img {
    opacity: 0.5;
}
.select-jige img{
    opacity: 1;
}
.det4 {
    display: flex;
    align-items: center;
    background: #F6F6F6;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 12px;
}
.ihtg45 {
    filter: brightness(0) invert(0);
    margin-right: 8px;
}
.edtt{
    color: #B1B1B1;
}
.edtt .ihtg45{
    opacity: 0.4;
}
.jhed24 {
    border-top: 1px dashed #000000;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}
.et4dsf {
    background: #F7F8F9;
    padding: 14px;
    margin: 10px 0;
}
.jgdr {
    border: 1px solid #989898;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: white;
}
.etasfw3 {
    color: #383838;
    font-size: 14px;
}
.dser34 {
    color: #383838;
    font-size: 15px;
}
.dser34 span {
    color: #5948DB;
    margin-left: 15px;
    position: relative;
}
.dser34 span:before {
    position: absolute;
    content: '';
    background: #D9D9D9;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 50%;
    left: -10px;
    transform: translate(0, -50%);
}
.irhtu4 {
    border: none;
    background: linear-gradient(45deg, #1F194E, #29206D);
    color: white;
    font-weight: 300;
    padding: 5px 25px;
    border-radius: 60px;
    font-size: 14px;
}
.sdt4e {
    color: #5948DB;
}
.hawurh {
    color: #5948DB !important;
    background-color: #E2EBFA;
    padding: 3px 5px;
    border-radius: 9px;
    font-size: 14px !important;
    margin-left: 10px;
    width: fit-content;
}
.swrq {
    background: #5948DB;
    color: white;
    padding: 8px 19px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 18px 18px 0 0;
}
.edt{
    width: 7px;
    height: 7px;
    border: 1px solid;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
}
.dsfed4e {
    padding: 10px;
    position: absolute;
    background: white;
    z-index: 99999;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    width: fit-content;
    left: 50%;
    transform: translate(-50%);
    display: none;
    margin: 0;
    font-size: 10px;
}
.dsfed4e p{
    white-space: nowrap;
}
.dsfed4e:before {
    content: "";
    position: absolute;
    top: -1px;
    left: 49%;
    transform: translate(-50%);
    width: 10px;
    height: 10px;
    background: #ffffff;
    rotate: 45deg;
    z-index: 9;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dedede;
}
.kiejr:hover .dsfed4e {
    display: block;
}
.two1{
    width: 7px;
    height: 7px;
    border: 1px solid;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    background: white;
}
.two2{
    width: 7px;
    height: 7px;
    border: 1px solid;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    background: white;
}
.jhrt5 {
    position: absolute;
    top: -10px;
    right: 0;
    font-size: 11px;
    color: #5948DB;
    font-weight: 500;
}
.dseaf{
    width: fit-content;
}
.hujdgt span:after {
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    background: white;
    top: 0;
    right: -13px;
}

.hujdgt span {
    position: relative;
    margin-right: 26px;
}
.hujdgt span:last-child::after {
    content: none;
}
.deut{
    font-weight: 500;
}
.sr3er{
    cursor: pointer;;
}
.uigds{
    display: none;
}
.bar34er {
    background: url(../images/baner1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 20px;
    border-radius: 9px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 20px;
}
.sarr5 {
    font-weight: 600;
    font-size: 23px;
    text-decoration: underline;
    color: #0c0066;
    margin-bottom: 5px;
}
.f4rt {
    font-weight: 500;
    color: #0c0066;
}
.fg56 {
    background: white;
    padding: 9px 19px;
    width: fit-content;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 12px;
    font-weight: 700;
    font-size: 20px;
    color: #0c0066;
    display: flex;
    align-items: center;
    cursor: pointer;
}
@keyframes trin {
    0% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    20%, 32%, 44%, 56%, 68% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    23%, 35%, 47%, 59%, 71% {
        transform: rotate3d(0, 0, 1, 15deg);
    }
    26%, 38%, 50%, 62%, 74% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    29%, 41%, 53%, 65%, 77% {
        transform: rotate3d(0, 0, 1, -15deg);
    }
    80% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}
.fg56 img{
    animation-name: trin;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.srgd2 {
    font-size: 12px;
}
.jai456{
    background: #e1e1e1;
    padding: 6px 0;
    display: none;
}
.dfedt {
    font-size: 13px;    
    margin-top: 4px;
}
.ejt6t5 {
    font-weight: 600;
}
.dftye3 {
    margin-left: 5px;
    border-left: 1px solid black;
    padding-left: 5px;
}
.osru3 {
    background: #251d61;
    color: white;
    border: none;
    font-size: 12px;
    padding: 5px 9px;
    border-radius: 6px;
}
.kiht34 {
    background: #ffffff;
    border: 1px solid #251d61;
    border-radius: 22px;
    padding: 4px 10px;
}
.et643{
    border-radius: 16px !important;
}
.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label,
.form-floating>.form-select:not(:placeholder-shown)~label{
    color:#5948db !important;
    opacity: 1 !important;
    top: -17px;
    height: fit-content;
    padding: 17px .75rem 0;
}
.form-control:focus {
    border-color: #5948db;
}
.form-floating>.form-control-plaintext:focus, .form-floating>.form-control-plaintext:not(:placeholder-shown), .form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-select {
    padding-top: 0.625rem;
}
.hgdr679 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background: white;
    border-radius: 16px;
    margin-top: 23px;
}
.dsctgj4 {
    background: #5948DB;
    color: white;
    border-radius: 16px 16px 0 0;
    padding: 15px;
    font-size: 19px;
    font-weight: 500;
}
.rwt64{
    padding: 0 15px;
}
.i665r {
    font-weight: 500;
    color: #525050;
    font-size: 18px;
    margin-bottom: 15px;
}
.dki97 {
    color: #525050;
    font-size: 15px;
    display: flex;
    align-items: start;
    margin-bottom: 11px;
}
.dgeh45 {
    color: #525050;
    font-size: 24px;
    font-weight: 600;
    margin: 6px 0;
}
.jge57d {
    color: #707070;
}
.uje57 {
    background: #5948DB;
    color: white;
    border: none;
    padding: 8px 33px;
    margin-bottom: 17px;
    border-radius: 6px;
    font-size: 21px;
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
}
.fdy45 {
    padding: 15px;
    border-top: 1px solid #c1c1c1;
    font-size: 10px;
    color: #707070;
    display: flex;
    align-items: start;
}
.ikdg5 {
    width: 72px;
    color: #525050;
    font-weight: 500;
}
.ikdg5 img{
    margin-right: 5px;
}
.mdui56{
    color: #525050;
    font-weight: 500;
}
.awrzr6{
    height: 48px;
}
.satw5r7 {
    height: 48px;
    width: 100%;
    border-radius: 0;
    border: 0;
    padding: 0 25px 0 0;
    position: relative;
    color: #909090;
    text-align: center;
}
.form-select{
    font-size: 14px;
    color: #909090;
}
.form-select:focus {
    border-color: #5948db;
    box-shadow: none;
}
.satw5r7 option:hover{
    background-color: #5948DB !important;
  }
.r634y input{
    height: 58px !important;
}
.satw5r7:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
.dshgt4 {
    border: 1px solid #e5e5e5;
    border-radius: 6px;
}
.form-floating>label {
    color: #909090;
    font-size: 14px;
    padding: 13px .75rem 0;
}
.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
    height: 10px;
    min-height: 48px;
}
.dstr64{
    color: #505050;
    font-weight: 500;
}
.hg78i{
    color: #5948DB;
    font-weight: 500;
}
.dsr97 {
    background: #F1F1FF;
    margin: 10px auto;
    padding: 10px 30px;
    border-radius: 11px;
    color: #525050;
    border: 1px solid #D6D6D6;
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: start;
}

.se54hu8 {
    background: #5948DB;
    color: white;
    border: 1px solid #5948DB;
}
.dsr97 .form-check-input {
    background-color: #ffffff00;
    border-color: #525050;
    margin-right: 6px;
}
.se54hu8 .form-check-input:checked{
    filter: none;
    border-color: white;
}
.jshr8{
    font-weight: 500;
}
.dst4u8 {
    font-size: 11px;
    color: #404040;
}
.fdy451 {
    padding: 15px;
    border-top: 1px dashed #c1c1c1;
    font-size: 11px;
    color: #707070;
    display: flex;
    align-items: start;
    margin: auto;
}
.ieyh65{
    color: #5948DB;
    font-size: 20px;
    font-weight: 600;
}
.ieyh65 span{
    color: #333333;
    font-size: 14px;
    font-weight: 400;
}
.jis67{
    color: #525050;
    font-weight: 500;
}
.fry547{
    color: #5948DB;
}
.dsjt {
    color: #333333;
    font-size: 12px;
    margin: 14px 0;
    text-align: center;
}
.jisr4{
    color: #5948DB;
}
.uet54 {
    background: #5948DB;
    color: white;
    border: none;
    border-radius: 11px;
    width: 30%;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    margin: auto;
    font-weight: 500;
}
.eri45{
    position: sticky;
    top: 87px;
    left: 0;
    margin: 0;
}
.jueg5 {
    border-bottom: 1px dashed #dddddd;
    padding: 10px 15px;
}
.jd679 {
    font-size: 19px;
    font-weight: 600;
}
.ht4jtr {
    padding: 10px 0;
    border-bottom: 1px dashed #dddddd;
}
.ht4jtr:last-child{
    border-bottom: none;
}
.prid9{
    color: #383838;
    font-weight: 500;
}
.man9d{
    color: #383838;
}
.jxdfh6{
    background-color: #5948DB;
    color: white !important;
}
.sdt5uy{
    position: relative;
}
.sdt5uy:hover .dsfed4e {
    display: block;
}
.se673ty {
    width: 690px;
    font-size: inherit;
    border-radius: 20px;
    padding: 20px;
}
.fonted{
    font-weight: 300;
    font-size: 13px;
}
.iujt4{
    font-size: 11px;
}
.uyr76{
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translate(0, -50%);
}
.uy897 {
    position: absolute;
    width: 270px;
    font-size: 10px;
    top: 28px;
    right: -7px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background: white;
    z-index: 99999 !important;
    color: #6B6B6B;
    display: none;
    border-radius: 5px;
}
.p-se5 {
    content: "";
    position: absolute;
    top: -5px;
    right: 11px;
    width: 10px;
    height: 10px;
    background: #ffffff;
    rotate: 45deg;
    z-index: 9;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dedede;
}
.uyr76:hover .uy897 {
    display: block !important;
}
.jgtr6{
    background-color: #5948db;
    color: white;
    margin-top: 35px;
    border-radius: 20px;
    padding: 18px;
    text-align: center;
    position: relative;
    padding-top: 35px;
}
.jisahr {
    position: absolute;
    top: -34px;
    left: 50%;
    transform: translate(-50%);
}
.jisahr {
    position: absolute;
    top: -34px;
}
.ued67{
    font-size: 34px;
    font-weight: 600;
}
.hn57d {
    color: #d0c9ffd6;
    font-size: 19px;
    font-weight: 600;
}
.urt78 {
    font-size: 15px;
    color: #ffffffc2;
}
.ij978 {
    text-align: center;
    padding-top: 0;
}
.iu857f {
    color: #818181;
    font-weight: 500;
    font-size: 17px;
}
.rtj95 {
    font-size: 35px;
    font-weight: 600;
    color: #e30000;
}
.etji4 {
    color: #626262;
    font-size: 17px;
    font-weight: 500;
    padding: 10px;
    border-bottom: 1px solid #dbd0d0;
}
.jie89 {
    color: #a1a0a0;
    padding: 10px;
    font-weight: 500;
}
.jie896 {
    padding: 10px;
    color: #202020;
}
.ryh69 {
    font-size: 22px;
    font-weight: 700;
    color: #201a4f;
    margin-top: 15px;
}
.jher5 {
    position: absolute;
    top: 0;
}
.jher56 {
    position: absolute;
    top: 12px;
    right: 16px;
    width: initial !important;
    height: initial !important;
}
.sret56:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 6px;
    width: 1px;
    height: 56%;
    background: #d8d8d8e0;
    z-index: 9;
    transform: translate(0, -50%);
}

.sret56 {
    position: relative;
}
.u4rtwr {
    overflow: hidden;
}
.u4rtwr .rwt64,
.u4rtwr .fdy45{
    display: none;
}
.yr8t5{
    cursor: pointer;
    rotate: 180deg;
}
.u4rtwr .yr8t5{
    rotate: 0deg;
}
.fyt5u {
    padding: 22px 10px;
}
.confir-m{
    color: #06ab76;
}
.eduit4 {
    border: 1px solid #06ab76;
    color: #565656;
    padding: 10px 40px;
    border-radius: 13px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background: #ffffff;
    font-weight: 500;
    font-size: 14px;
}
.sett5:before {
    position: absolute;
    content: '';
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #939393;
}

.sett5 {
    position: relative;
    width: fit-content;
    color: #939393;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 12px !important;
}
.t4sdr57 {
    margin: 15px 0;
}
.rte6t {
    font-weight: 500;
    color: #4e4e4e;
}
.khrt5 {
    font-weight: 500;
    color: #3a3a3a;
}
.uer42 {
    position: absolute;
    top: 12px;
    right: 9px;
}
.set48f{
    border-color: #ff0000 !important;
}
 .form-floating>.set48f:focus~label, .set48f .form-floating>.set48f:not(:placeholder-shown)~label{
    color: #ff0000 !important;
}
.uit {
    background: #ecf7ec;
    padding: 20px;
    border-radius: 20px;
}
.hdr68{
    color: #0e6f0e;
    margin-bottom: 3px !important;
}
.uryt8{
    color: #006600;
    font-weight: 500;
    margin: 0;
}
.uiety4 {
    width: 30px;
    height: 30px;
    outline: 1px solid white;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.uiety4 img {
    width: 18px;
}
.jxdfh62 {
    background-color: #ffffff;
    color: #06ab76 !important;
    border-top: 1px solid #d7d7d7;
    font-weight: 600;
    font-size: 18px;
    padding: 7px 0px;
}
.prid92 {
    color: #06ab76;
    font-weight: 600;
}
.overjkg{
    overflow: hidden;
}
.foent img {
    width: 32px;
    height: 32px;
}
.even:nth-child(even) {
    background-color: #f0f0f0;
  }
  .fy56 {
    filter: unset;
    opacity: unset;
    border: 1px solid #D0D0D0;
}
.error {
    color: red;
    font-size: 10px;
}
.uht {
    accent-color: #5948db;
    cursor: pointer;
}

.atfe{
    z-index: 99999999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000000b8;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .jgur67{
    overflow: hidden;
    height: 100vh;
   }

   .khrt5i8{
    margin-top: 20px;
    font-weight: 600;
    font-size: 65px;
    color: #271e67;
  }
 .urhue45{
    display: none;
 }

 .iyedt{
    font-size: 11px;
    color: #6f6f6f;
 }
 @keyframes trin {
    0% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    20%, 32%, 44%, 56%, 68% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    23%, 35%, 47%, 59%, 71% {
        transform: rotate3d(0, 0, 1, 15deg);
    }
    26%, 38%, 50%, 62%, 74% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    29%, 41%, 53%, 65%, 77% {
        transform: rotate3d(0, 0, 1, -15deg);
    }
    80% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

.rdrDefinedRangesWrapper{
    display: none;
}
.rdrDateRangePickerWrapper{
    position: absolute;
    z-index: 99;
}
.rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
.rdrDayToday .rdrDayNumber span:after {
    background: #5948db;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background: rgb(89 72 219);
}
.rdrDateDisplayItemActive {
    border-color: rgb(89 72 219);
}
.rdrDayHovered:hover{
    border-color: rgb(89 72 219) !important;
}

.spinner12 {
    font-size: 19px;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
  }
 
  
  .spinner12 .spinner-blade {
    position: absolute;
    left: 0.4629em;
    bottom: 0;
    width: 0.099em;
    height: 0.4777em;
    border-radius: 0.0555em;
    background-color: transparent;
    -webkit-transform-origin: center -0.2222em;
    -ms-transform-origin: center -0.2222em;
    transform-origin: center -0.2222em;
    animation: spinner-fade9234 1s infinite linear;
  }
  
  .spinner12 .spinner-blade:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  
  .spinner12 .spinner-blade:nth-child(2) {
    -webkit-animation-delay: 0.083s;
    animation-delay: 0.083s;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  
  .spinner12 .spinner-blade:nth-child(3) {
    -webkit-animation-delay: 0.166s;
    animation-delay: 0.166s;
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  
  .spinner12 .spinner-blade:nth-child(4) {
    -webkit-animation-delay: 0.249s;
    animation-delay: 0.249s;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  .spinner12 .spinner-blade:nth-child(5) {
    -webkit-animation-delay: 0.332s;
    animation-delay: 0.332s;
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  
  .spinner12 .spinner-blade:nth-child(6) {
    -webkit-animation-delay: 0.415s;
    animation-delay: 0.415s;
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  
  .spinner12 .spinner-blade:nth-child(7) {
    -webkit-animation-delay: 0.498s;
    animation-delay: 0.498s;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .spinner12 .spinner-blade:nth-child(8) {
    -webkit-animation-delay: 0.581s;
    animation-delay: 0.581s;
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  
  .spinner12 .spinner-blade:nth-child(9) {
    -webkit-animation-delay: 0.664s;
    animation-delay: 0.664s;
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  
  .spinner12 .spinner-blade:nth-child(10) {
    -webkit-animation-delay: 0.747s;
    animation-delay: 0.747s;
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  
  .spinner12 .spinner-blade:nth-child(11) {
    -webkit-animation-delay: 0.83s;
    animation-delay: 0.83s;
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  
  .spinner12 .spinner-blade:nth-child(12) {
    -webkit-animation-delay: 0.913s;
    animation-delay: 0.913s;
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  
  @keyframes spinner-fade9234 {
    0% {
      background-color: #ffffff;
    }
  
    100% {
      background-color: transparent;
    }
  }

  .bagdis{
    display: none;
  }

    .hriut8{
        color: #5948db;
      }
      .hriut8 img{
        rotate: 180deg;
      }
      .jgug87 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .kjdh6 {
        background: #b0afb91f;
        padding: 20px;
        border-radius: 10px;
        margin: 0 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    .kjdh6 h5 {
        border-bottom: 1px solid #1a1a1a;
        width: fit-content;
        padding-bottom: 2px;
    }
    .jgug87 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d8d8d8;
        padding: 10px;
    }
    .jgug87:last-child {
        border-bottom: none;
    }
    .sdhgf{
        font-size: 10px;
        text-align: center;
        margin-bottom: 6px;
        border: 1px solid #ebebeb;
        padding: 2px;
        border-radius: 10px;
        font-weight: 500;
    }
    .hsgr4e {
        background: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        padding: 16px;
        border-radius: 10px;
        margin-bottom: 20px;
    }
    .hsgr4e:hover{
        background: #5948db12;
    }
    .hsgr4e:hover .hr59w7 {
        background: #f3f2fc;
    }
    .hr59w1 {
        color: #5948db;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .hr59w2 {
        font-weight: 600;
        font-size: 21px;
    }
    .hr59w3 {
        font-size: 14px;
        color: #626060;
    }
    .hr59w4 {
        color: #5948db;
        font-size: 19px;
        font-weight: 700;
        text-align: right;
    }
    .hr59w5 {
        font-size: 12px;
        text-align: right;
        color: #666666;
    }
    .hr59w6{
        position: relative;
        width: 100%;
        height: 2px;
        border-bottom: 1px dashed #959595;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .hr59w7 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -45%);
        background: #ffffff;
        padding: 2px;
    }
    .hr59w6:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0%, -40%);
        width: 7px;
        height: 7px;
        background: #5948DB;
        border-radius: 20px;
    }
    .hr59w6:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translate(0%, -40%);
        width: 7px;
        height: 7px;
        background: #5948DB;
        border-radius: 20px;
    }
    .uyut7 {
        background: #1f194e;
        color: white;
        text-align: center;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ieyt54{
        width: 100%;
    }
    .es56{
        height: 100px !important;
    }
    .hr6 {
        background: #5948db;
        border-radius: 40px;
        margin-right: 10px;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hr6 img {
        filter: brightness(0) invert(1);
    }
    .dsry4{
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        z-index: 9;
        position: relative;
        padding: 10px;
    }
    .esate1 {
        padding: 3px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-radius: 20px;
        background: #5948db0d;
        font-weight: 600;
    }
    ._2ibL0 {
        margin: 8px 0;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        scrollbar-width: none;
    }
    ._2ibL0 ._39tHq {
        padding-left: 8px;
        z-index: 1;
        background: #fff;
        position: -webkit-sticky;
        position: sticky;
        left: 0;
    }
    ._2ibL0 ._39tHq>span, ._2ibL0 ._3DqJY>span, ._2ibL0 ._1DS50>span, ._2ibL0 ._3_-gd>span, ._2ibL0 ._1GLlw>span, ._2ibL0>span {
        border: 1px solid rgba(16, 16, 16, .13);
        padding: 7px 10px 7px 12px;
        border-radius: 50px;
        margin-right: 8px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        color: #101010;
        font-weight: 400;
        line-height: 20px;
    }
    ._2ibL0 ._39tHq span {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow-x: hidden;
        max-width: 84px;
        -webkit-transition: max-width .5s;
        transition: max-width .5s;
        background-color: #fff;
    }
    ._2ibL0 ._39tHq span ._2AOzP {
        font-size: 16px;
        color: #101010;
        font-weight: 400;
        line-height: 20px;
    }
    ._2ibL0 ._39tHq span ._2iaTS {
        margin-left: 2px;
        visibility: visible;
        opacity: 1;
        -webkit-transition: max-width .5s, visibility .5s, opacity .5s;
        transition: max-width .5s, visibility .5s, opacity .5s;
        max-width: 50px;
    }
    ._2ibL0>span {
        background-color: #fff;
    }
    ._2ibL0 ._39tHq>span ._2l1il, ._2ibL0 ._3DqJY>span ._2l1il, ._2ibL0 ._1DS50>span ._2l1il, ._2ibL0 ._3_-gd>span ._2l1il, ._2ibL0 ._1GLlw>span ._2l1il, ._2ibL0>span ._2l1il {
        margin-left: 8px;
        width: 16px;
        height: 16px;
    }
    .auri4 {
        margin-bottom: 0 !important;
        font-size: 15px;
        background: #5948db;
        color: white;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding: 0;
        margin-left: 5px;
    }
    .ioer5{
        width: 20px;
    }
    .desk-hide{
        display: none;
    }
    .ie4yt4 {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
    }
    .ie4yt4 li{
       width: 33%;
       margin: 5px 0;
    }
    .sdft4{
        max-height: 143px;
    }
    .ujyt8 {
        font-weight: 600;
        text-decoration: underline;
        text-align: right;
        cursor: pointer;
    }
    .textBox {
        font-size: 15px;
        line-height: 30px;
        padding: 40px 0 10px;
        width: 100%;
    }
    .baggage-layer {
        background: #5948db;
        border-bottom: 1px solid #5948db;
        color: #fff;
        float: left;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        margin-left: 1%;
        margin-right: 1%;
        padding: 10px;
        width: 48%;
    }
    .airline-layer {
        border-bottom: 1px dashed #ccc;
        float: left;
        margin-bottom: 15px;
        margin-left: 1%;
        margin-right: 1%;
        padding-bottom: 15px;
        width: 48%;
    }
    .data img {
        border-radius: 8px;
        margin-left: 10px;
        margin-right: 10px;
        outline: 1px solid #fff;
        outline-offset: -1px;
        width: 35px;
    }
    .data a {
        line-height: 35px;
        text-decoration: underline;
    }
    .tabcontent2 {
        background: #fff;
        border: 1px solid #ccc;
        padding: 10px 0;
    }

    .sar3d {
        border: 1px solid #5948DB;
        color: #5948db;
        padding: 15px 45px;
        border-radius: 10px;
        width: fit-content;
        margin: auto;
    }
    thead tr {
        position: sticky;
        top: 10px;
    }
    thead tr th {
        padding: 5px;
    }
    thead tr {
        background-color: #002192;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
    thead {
        position: relative;
    }
    tbody, td, tfoot, th, thead, tr {
        border: 0 solid;
        border-color: inherit;
    }

    tbody tr td {
        color: #404040;
        font-size: 13px;
        padding: 5px;
        text-align: center;
    }
    table, td, th {
        background-color: #fff;
        border: 2px solid #eee;
        border-collapse: collapse;
    }
    th {
        color: #002192;
        font-size: 16px;
        font-weight: 550;
    }
    table {
        margin: auto;
        max-width: 1200px;
        position: relative;
        width: 94%;
    }
    .rc-slider-track, .rc-slider-tracks {
        background-color: #5948db !important;
    }
    .rc-slider-handle {
        background-color: #5948db !important;
        outline: 1px solid #5948db !important;
        opacity: 1 !important;
        border-color: white !important;
    }
    .rc-slider-rail {
        background-color: #5948DB !important;
        border-radius: 6px;
        height: 1px;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
    }
    .rc-slider-handle:hover {
        border-color: #968cdd !important;
    }
    .rc-slider-track, .rc-slider-tracks {
        border-radius: 6px;
        height: 3px;
        position: absolute;
    }
    .clients {
        margin: 10px auto;
    }
    .clients .client-image {
        display: inline-table;
        height: 80px;
        margin: 5px;
        text-align: center;
        width: 80px;
    }
    .img01 {
        border: 3px solid #fff;
        box-shadow: 5px 0 15px #ccc;
        transition: transform .8s ease-in-out;
    }
    .img01:hover {
        border: 3px solid #5948db;
        box-shadow: 1px 1px 1px #ccc;
    }
    .srchbg {
        background-color: #fff;
        border: 1px solid #c7cfcf;
        border-radius: 8px;
        box-shadow: 0 5px 0 #ccc;
        margin-top: 30px;
        padding: 20px 10px 10px;
    }
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging ,
.rc-slider-handle:active {
    border-color: #5948db !important;
    box-shadow: 0 0 5px #5948db !important;
}
.ihteike li a:hover{
 text-decoration: underline;
}
.scolo img:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 70px;
}
.oseru4{
    width: 50%;
}
.rdrCalendarWrapper {
    height: fit-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
    overflow: hidden;
}
.rdrWeekDay {
    font-weight: 500;
    color: rgb(34 27 87);
    font-size: 13px;
}
.rdrMonthName {
    text-align: center;
    font-weight: 600;
    color: #271e66;
    position: absolute;
    top: -58px;
    width: 100%;
    left: 0;
    font-size: 18px;
}
.rdrMonth {
    height: fit-content !important;
    position: relative;
}
.rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
    z-index: 9999;
    position: relative;
}
.rdrMonthAndYearPickers {
    font-weight: 600;
    display: none;
}
.rdrDayNumber{
    font-weight: 500;
}
.rdrCalendarWrapper {
    height: fit-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
    overflow: hidden;
}
.rdrMonthAndYearWrapper{
padding-top: 0 !important;
background: #5948db30;
}

.rdrDateDisplayWrapper {
    background-color: rgb(239, 242, 247);
    display: none;
}
.rdrMonths:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 96%;
    border-right: 1px dashed #c7c7c7;
}

.rdrMonths {
    display: flex;
    position: relative;
}
.rdrDateRangePickerWrapper {
    position: absolute;
    z-index: 99;
    width: fit-content;
    margin-top: -10px;
    padding: 0;
}
.fdhy5{
    line-height: 14px;
    margin-bottom: 8px;
}
.sttde5:hover {
    background: #5948db;
    color: white;
}
.cursor-point{
    cursor: pointer;
}
.fyt5r {
    padding: 25px 20px 0;
}
.ft6i1:before {
    content: "";
    background: url(../images/icon/lefty.png);
    position: absolute;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;
}
.ft6i2:before {
    content: "";
    background: url(../images/icon/righty.png);
    position: absolute;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;
}
.krt img{
    display: none;
}
.disabled{
    opacity: 0.5;
    pointer-events: none;
}
.select-mat {
    background: #b7b7b7 !important;
}
.e38ie3 {
    background-color: #EBE9FF !important;
    color: #525050 !important;
}
.jhere23{
    font-size: 11px;
}
.ou5tft7 {
    padding: 10px 0;
    display: flex;
    justify-content: end;
}
.ir95w {
    align-items: center;
    border: 2px solid #dbdbdb;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin: 0 4px 10px;
    opacity: 0.6;
}
.ou5tft7 img {
    margin: 1px 5px;
}
.set54f{
    border-color: #014397;
    opacity: 1;
}
.dki97 img{
    margin-top: 2px;
}
.ajwri4{
    position: sticky;
    width: 100%;
    top: 0;
    background: white;
    height: 38px;
    z-index: 999;
}
.ihteike li a {
    font-size: 13px;
}
.se673ty p {
    white-space: unset !important;
}
.zdujr-pop body{
    overflow-y: hidden !important;
}
.iur85ja h2,
.iur85ja h3{
 font-size: 25px;
}
.jseri:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    width: 10px;
    height: 10px;
    background: #ffffff;
    rotate: 134deg;
    transform: translate(-7px, -24%);
    box-shadow: rgb(0 0 0 / 7%) 2px 2px 0px;
}
.jseri {
    font-size: 12px;
    position: absolute;
    margin: 0;
    right: -9px;
    top: 50%;
    width: fit-content;
    background: white;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transform: translate(100%, -50%);
    z-index: 9;
    border-radius: 4px;
    display: none;
}
.sgr34:hover .jseri {
    display: block;
}
.wsrjk4 {
    margin-bottom: 0;
    font-size: 13px;
    margin-top: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    padding: 0 11px;
    text-align: center;
}
.jda34 .edjhire {
    width: fit-content;
}
.jdtr54 {
    width: fit-content;
    position: relative;
}
.uyr761 {
    right: -21px !important;
}
.uy8971 {
    width: 150px;
}
.sdtg4r{
    --bs-form-select-bg-img: none;
}

@media only screen and (max-width: 600px){
    .dstegr4 {
        border-top: 1px dashed #c7c7c7;
        padding-top: 10px !important;
        width: inherit;
        border-left: none;
    }
    .wrerew{
        width: inherit;
    }
   .sttedr{
    display: block;
    filter: brightness(0) invert(1);
    animation-name: trin;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
   }

    .fir-maib-r {
        padding-top: 16px;
        padding-bottom: 40px;
    }
    .jhtudt {
        font-size: 12px;
    }
    .tabuiy1 .dttre4 {
        padding-left: 11px;
        padding-right: 11px;
    }
    .tabuiy {
        padding: 11px 11px 0px 11px;
    }
    .tabuiy1 img {
        width: 16px;
    }
    .sfsdt {
        padding-bottom: 16px;
    }
    .jkdgt {
        right: 7%;
        left: inherit;
        top: 50%;
        transform: translate(0, -69%);
    }
    .iure4{
        padding: 0;
    }
    .jai456 {
        display: block;
    }
    .srh1 {
        padding-top: 38px;
        background: #261e63;
        width: 100%;
        height: calc(100% - 14px);
        max-height: calc(100% - 14px);
        border-radius: 13px 13px 0 0;
        position: relative;
    }
    .urhue45 {
        position: absolute;
        top: 22px;
        left: 7px;
        display: block;
        z-index: 9999;
        filter: brightness(0) invert(1);
    }
    .zdujr-pop2,
    .zdujr-pop1,
    .zdujr-pop {
        width: 100%;
        bottom: -8000px;
        top: unset;
        height: 100%;
        left: 0;
        border: none;
        background: rgba(0, 0, 0, 0.5);
        padding-top: 10px;
        display: flex;
        align-items: end;
        justify-content: center;
        position: fixed;
        overflow: hidden;
        transition: opacity 0.5s ease-in-out, bottom 0.5s ease-in-out;
        border-radius: 0;
        flex-flow: wrap-reverse;
        z-index: 9999999;
        padding-bottom: 0 !important;
    }
    .zdujr-pop3{
        width: 100%;
        bottom: -8000px;
        top: unset;
        height: 100%;
        left: 0;
        padding-top: 10px;
        border: none;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: end;
        justify-content: center;
        position: fixed;
        overflow: hidden;
        transition: opacity 0.5s ease-in-out, bottom 0.5s ease-in-out;
        border-radius: 0;
        flex-flow: wrap-reverse;
        z-index: 9999999;
        padding-bottom: 0 !important;
    }
    .engiht12 {
        padding: 20px 0;
    }
    .urhue4 {
        position: absolute;
        top: 22px;
        left: 7px;
        display: block;
        z-index: 9999;
    }
    .uge {
        max-height: 80%;
    }
    .kdti4 {
        position: absolute;
        top: 7px;
        left: 50%;
        transform: translate(-50%, 0);
        border: none;
        background: #d1d1d1;
        width: 45px;
        height: 3px;
        border-radius: 9px;
        display: block;
    }
    .r634y{
        border: 1px solid #a7a7a7;
        margin: 22px 10px;
        border-radius: 10px;
        overflow: hidden;
    }
    .khetr{
        bottom: 0 !important;
    }
    .srh {
        padding-top: 38px;
        background: white;
        width: 100%;
        height: calc(100% - 14px);
        max-height: calc(100% - 14px);
        border-radius: 13px 13px 0 0;
        position: relative;
    }
    .srh35 {
        background: white;
        width: 100%;
        height: calc(100% - 14px);
        max-height: calc(100% - 14px);
        border-radius: 13px 13px 0 0;
        position: relative;
        overflow: scroll;
        scrollbar-width: none;
    }
    .srh2 {
        padding-top: 38px;
        background: white;
        width: 100%;
        height: calc(100% - 14px);
        max-height: calc(100% - 14px);
        border-radius: 13px 13px 0 0;
        position: relative;
    }
    .sdetr{
        position: inherit;
    }
    .asrije {
        padding: 18px;
    }
    .mob-hide{
        display: none !important;
    }
    .ry634{
        padding-left: 0;
    }
    .wrs6rr33 {
        height: 250px;
    }
    .ier9u{
        display: inherit;
    }
    .swrs {
        padding: 4px;
        position: absolute;
        bottom: 24px;
        right: 10px;
    }
    .seiry53 {
        padding: 0;
        padding-bottom: 12px;
    }
    .jet5 {
        padding: 20px 10px;
    }
    .isyhe4 {
        font-size: 13px;
    }
    .jkdhtj {
        width: 100%;
        padding: 11px;
        margin-top: 13px;
        border-radius: 14px;
    }
    .dtghgsr {
        margin-top: 13px;
    }
    .otuji4 {
        margin-right: 0 !important;
        text-align: center;
    }
    .uishr{
        width: 100%;
        padding: 0;
    }
    .ewtrt {
        background: #5948db;
    }
    .seir3e,
    .srij4e a,
    .fdktjr,
    .eedt54,
    .idrhue{
        color: #ffffff;
    }
    .esate img{
        filter: brightness(0) invert(1);
    }
    .ieyht4{
        border-color: white;
    }
    .siryh4 {
        position: absolute;
        top: 16px;
    }
    .sjr4 {
        height: 100%;
    }
    .sjr4 .row {
        height: 100%;
        align-items: end;
    }
    .ueyru4 {
        font-size: 19px;
        margin-bottom: 5px;
    }
    .kdht4 {
        font-size: 22px;
        margin-bottom: 20px;
        line-height: 30px;
    }
    .resty {
        padding: 4px;
    }
    .engiht{
        padding: 8px;
        border-radius: 10px;
    }
    .jkdgt img{
        rotate: 90deg;
    }
    .navbar-toggler {
        padding: 0;
        border: none;
    }
    .navbar-toggler-icon {
        width: 24px;
        height: 24px;
        filter: brightness(0) invert(1);
    }
    .navbar-toggler:focus{
        box-shadow: none;
    }
    .eedt54 {
        text-align: left;
    }
    .dstye {
        bottom: 9px;
        display: block;
        left: 0;
        position: sticky;
        width: 100%;
        z-index: 99;
        margin: 10px 0;
    }
    .iher4 {
        background: #271f68;
        color: white;
        padding:8px;
        border-radius: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        
    }
    .edt45{
        transform: translate(-99%);
    }
    .sry6ds {
        font-size: 11px;
        font-weight: 300;
    }
    .kidhte{
        filter: brightness(0) invert(1);
        animation-name: trin;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    .fyt5r {
        background: #5948db47;
        padding: 5px !important;
        margin-bottom: 18px;
        border-radius: 10px;
        justify-content: space-between;
    }
    .srr33 {
        background: #5948db;
        color: #ffffff;
    }
    .sdrt31{
        padding: 6px;
        margin: 0 !important;
        border-radius: 7px;
        width: 49%;
        text-align: center;
    }
    .iutit{
        width: 110px;
        filter: brightness(0) invert(1);
    }
    header{
        background: #251d61;
        border-bottom: 1px solid #ffffff45;
    }
    .swtr3s {
        margin-top: 17px;
        border-bottom: none;
    }
    .yhsdt4 {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    .se6tet{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed #EBEBEB;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .uigds {
        border: 1px solid white;
        border-radius: 25px;
        align-items: center !important;
        padding: 3px 15px 3px 4px;
        display: block;
    }
    .dfyhr5 {
        font-size: 13px;
    }
    .dfsdfh {
        font-size: 10px;
    }
    .srtwe{
        width: 38px;
        border: 1px solid white;
        border-radius: 50%;
        display: block;
    }
    .jker4{
       padding: 0;
    }
    .edjhire{
        font-size: 14px;
        margin-top: 10px;
    }
    .kijdte span {
        display: none;
    }
    .oejrsxr{
        margin: 0;
    }
    .ikhere {
        font-size: 18px;
        margin: 0;
    }
    .ikhee {
        font-size: 15px;
        display: none;
    }
    .iyedt{
        margin: 0;
    }
    .idrhts {
        padding: 9px 24px;
    }
    .usyhrt{
        padding: 10px 7px 7px;
    }
    .kijdte{
        margin: 0;
    }
    .jhrfy {
        border-top: 1px dashed #adadad;
    }
    .ikdg5 {
        width: 274px;
    }
    .fdy45 {
        padding: 10px;
        font-size: 12px;
    }
    .dry5e{
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .uiety4 {
        width: 22px;
        height: 22px;
    }
    .uiety4 img {
        width: 14px;
    }
    .jsryt7{
        font-size: 15px;
    }
    .dsr97 {
        padding: 10px 10px;
        font-size: 14px;
    }
    .iedytit{
        width: 100%;
    }
    .se673ty {
        width: 212px;
        padding: 10px;
    }
    .eri45 {
        margin-top: 20px;
    }
    .uet54 {
        width: 80%;
        margin-bottom: 30px;
    }
    .borje4 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
    }
    .uyr76{
        z-index: 9;
    }
    .uje57{
        padding: 8px 26px;
        margin-right: auto;
    }
    .hriut8 {
        margin-top: 15px;
    }
    .ut4rs4{
        margin-top: 10px;
    }
    .ued67 {
        font-size: 24px;
        font-weight: 600;
    }
    .urt78 {
        font-size: 12px;
    }
    .eduit4 {
        margin-bottom: 20px;
    }
    .iu857f {
        margin-top: 12px;
    }
    .jie89, .jie896 {
        padding: 0;
        font-size: 14px;
    }
    .uit {
        margin-top: 22px;
    }
    .hsgr4e {
        padding: 7px;
    }
    .hr59w3 {
        font-size: 12px;
    }
    .hr59w8 {
        border-top: 1px solid #c7c7c7;
        margin-top: 8px;
        padding-top: 6px;
    }
    .hr59w4,
    .hr59w5{
        text-align: center;
    }
    .esate1 {
        flex-direction: column;
        margin-bottom: 20px;
    }
    .esate1 .fdktjr {
        color: #505050 !important;
        text-align: center;
    }
    .desk-hide{
        display: block;
    }
    .baggage-layer {
        font-size: 13px;
        width: 98%;
    }
    .airline-layer {
        width: 98%;
    }
    .hidden-xs {
        display: none;
    }
    .data a {
        font-size: 12px;
    }
    .data b {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }
    .data .col-8 {
        display: flex;
    }
    .jjeisr4 {
        margin-bottom: 4px;
    }
    .jf58u7 img {
        width: 100%;
    }
    .clients {
        text-align: center;
    }
    .kheewr {
        border-bottom: 1px solid #C9C9C9;
        padding: 4px 25px;
        border-right: none;
    }
    .dstkje {
        background: #ffffff;
        display: block;
        box-shadow: none;
    }
    .kheewr:last-child {
        border-bottom: none;
    }
    .sdtew::before{
        display: none;
    }
    .kheewr:hover {
        background: #2c246d2b;
    }
    .jgdr {
        height: 40px;
        width: 40px;
    }
    .dser34 {
        font-size: 13px;
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        padding-left: 25px;
    }
    .sjfge{
            position: absolute;
            top: 68%;
            right: -12px;
            width: 12%;
            z-index: 999999999999;
            rotate: 270deg;
            transform: translate(50%, 0%);
    }
    .dt54s span{
        font-size: 12px;
    }
    .rdrMonths:before {
       display: none;
    }
    .rdrMonthAndYearPickers {
        display: block !important;
    }
    .rdrMonthAndYearPickers select {
        border: 1px solid #282069;
        color: #281f6a;
        font-weight: 600;
    }
    .rdrDateRangePickerWrapper {
        left: 50%;
        transform: translate(-50%);
    }
    .ir95w img {
        width: 37px;
    }
    .dser54{
        flex-wrap: wrap;
        gap: 13px;
    }
    .hawurh {
        font-size: 10px !important;
    }
    .orgin-eror {
        font-size: 12px;
        position: inherit;
        margin-bottom: 14px;
    }
    .ie4yt4 li {
        width: 100%;
    }
    .edryte {
        font-size: 19px;
        color: #5948db;
    }
}


.phone-call {
    background: #177bad0d;
    border-radius: 5px;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    position: relative;
}
.fare-det{
    font-size: 15px;
    color: #767676;
    text-align: left !important;
}
.fare-pri{
    text-align: left !important;
    font-size: 20px;
    font-weight: 500;
    color: #177fb0;
}
.colr-chn{
    color: #6d6d6d;
    font-size: 14px;
}
.call-no{
    text-align: center !important;
    font-size: 14px;
}
.no-cal{
    text-align: center !important;
    font-size: 20px;
}
.cll-btn {
    width: 100%;
    background: #f48317;
    border: #f48317;
}
.cll-btn:hover,
.cll-btn:active {
    background: #177fb0 !important;
    border: #177fb0 !important;
}
.phone-call:after {
    content: "";
    position: absolute;
    top: 8px;
    left: -7px;
    width: 151px;
    height: 30px;
    background: #177fb0;
    z-index: -1;
    border-radius: 3px;
}
.sm-locaf{
    color: white;
    font-size: 15px;
}
.locti{
    color: black;
    font-weight: 500;
    font-size: 17px;
}
.curv {
    position: absolute;
    top: 30px;
    left: -4px;
    width: 11px;
    height: 15px;
    background: #177fb0;
    rotate: 138deg;
    border-radius: 3px;
    z-index: -999999;
}
.phone-call:before {
    content: '';
    position: absolute;
    top: 37px;
    border-bottom: 6px solid transparent;
    left: -6px;
    border-right: 6px solid #177fb0;
}
.fdy4555 {
    align-items: start;
    color: #707070;
    display: flex;
    font-size: 13px;
    margin-top: 11px;
}

.rc-slider-handle-2{
    display: none !important;
}
.fgered {
    margin: 0;
    min-height: 48px;
    font-size: 14px;
}
.fgered::placeholder{
    color: #929292;
}
.react-datepicker-wrapper{
    width: 100%;
}
.fhhd{
    z-index: 999;
}
.fhhd1{
    z-index: 99;
}

.swru46y {
    border: 2px solid #ff6600;
    width: 90%;
    margin: 12px auto;
    padding: 10px;
    border-radius: 6px;
    font-size: 20px;
    color: #ff6600;
    font-weight: 400;
  }
  .jh5r{
    border-radius: 9px;
}
.dfjt {
    border-radius: 8px;
    background: white;
    padding-bottom: 12px;
}
.jrt {
    position: relative;
    margin-bottom: 15px;
}
.jrt p {
    position: absolute;
    bottom: -7px;
    left: 0;
}

.jrt img {
    border-radius: 8px 8px 0 0;
    height: 250px;
}
.jrt:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #0000008a, #00000000);
    border-radius: 8px 8px 0 0;
}
.jrt p {
    position: absolute;
    bottom: 14px;
    left: 0;
    color: white;
    font-size: 21px;
    margin: 0;
    width: 100%;
    text-align: center;
    text-shadow: 3px 2px 4px black;
}
.detdr4 {
    width: 65px !important;
    height: 65px;
    border-radius: 8px;
}
.dt4w {
    font-size: 12px;
    color: #505050;
    font-weight: 400;
}
.wae3t {
    font-weight: 600;
    color: #303030;
}
.gyr4 {
    color: #303030;
    font-size: 12px;
    font-weight: 400;
}
.gt4e {
    font-weight: 600;
    font-size: 20px;
    color: #1F194E;
}

.edrit {
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #5948db;
    padding: 8px 20px;
    color: #5948db;
    display: flex;
    margin: auto;
    font-weight: 500;
}
.edrit:hover {
    background: #5948DB;
    border: none;
    color: white;
}
.w-90{
    width: 90%;
}
.hrth {
    color: #303030;
    font-weight: 600;
    margin-bottom: 20px;
}
.ujegtr4 {
    background: url(../images/flight/low-img.jpg);
    background-repeat: no-repeat;
    color: white;
    height: 454px;
    background-size: cover;
    background-position: left center;
    position: relative;
}
.ujegtr4:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(175deg, #0000009c, transparent);
}
.srth6 {
    font-weight: 600;
    font-size: 28px;
}
.kh5tsa {
    font-size: 18px;
}
.hrdt {
    background: #5948DB;
    color: white;
    padding: 20px;
}
.ujrt {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ir66re {
    border: 1px solid white;
    padding: 3px;
    border-radius: 50%;
    margin-right: 12px;
}
.dethik4 {
    font-size: 24px;
    font-weight: 300;
}
.dethik4 span{
    font-size: 28px;
    font-weight: 600;
}
.krh23{
    font-size: 30px !important;
    font-weight: 400 !important;
}
.fy5rs3 {
    color: #303030;
}
.dirh4e li {
    list-style: decimal !important;
    color: #303030;
    margin-bottom: 14px;
    line-height: 28px;
}
.dirh4e li span{
    font-weight: 500    ;
}
.utghfh{
    background-size: cover;
    background-repeat: no-repeat;
}
@media only screen and (max-width: 600px) {
    .fare-det,
    .fare-pri {
    text-align: center !important;
    margin: 0 !important;
}
.call-no {
        margin-top: 3px;
    }
.colr-chn {
    font-size: 12px;
}
.one-line {
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.cll-btn {
        width: 64%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
.sm-locaf {
    margin: 0;
}
.no-cal {
    margin: 0 !important;
}
.wif{
    width: 22px;
}
.phon-bor {
        border-top: 1px solid #818181;
        justify-content: center;
        padding-top: 9px;
    }
    .tugr {
        align-items: center;
        margin-top: 9px;
        margin-bottom: 5px;
        border-top: 2px solid #c0c1c3;
    }

.fggr{
    color: black;
}
.bor-st{
    border-left: 0 !important;
}
.phone-call-dis{
    display: none;
}
.call-ico{
    width: 14%;
    margin-right: 7px;
}
.prid92 {
    font-size: 17px;
}
.rftw4 {
    border: none;
}
.dftgdfty {
    gap: 16px;
}
.edrit {
    padding: 6px 12px;
}
.dfjt {
    margin-bottom: 20px;
}
.ujegtr4 {
    height: 100% !important;
}
.ujegtr4:before {
    background: linear-gradient(175deg, #00000094, #00000094);
}
.dethik4 span {
    font-size: 13px;
}
.hrdt {
    padding: 8px;
}
.dethik4 {
    font-size: 13px;
}
.krh23 {
    font-size: 15px !important;
}
.ir66re {
    margin-right: 10px;
}
.utghfh {
    background-image: none;
    background: linear-gradient(45deg, #1F194E, #29206D);
}
.f4rt {
    text-align: right;
    padding-left: 58px;
}
.fdhdfg{
    justify-items: end;
}
}


  @media (min-width: 600px) and (max-width: 1024px) {
    .mob-hide{
        display: none !important;
    }
  }
  .abcdefg{
    font-size: 12px;
  }
  .et3xt1 {
    color: #5948DB;
    font-weight: 500;
    font-size: 10px;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    top: -4px;
    right: 0;
    transform: translate(100%);
    display: none;
}
.sgr34:hover .et3xt1{
    display: block;
} 

.ibL0 {
    margin-left: 8px;
    width: 15px;
    height: 9px;
}

.spinner14 {
    --clr: rgb(89 72 219);
    --gap: 6px;
      /* gap between each circle */
   
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap);
   }
   .ew63ew:hover .spinner14{
    --clr: white !important;
   }
   
   .spinner14 span {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: var(--clr);
    opacity: 0;
   }
   
   .spinner14 span:nth-child(1) {
    animation: fade 1s ease-in-out infinite;
   }
   
   .spinner14 span:nth-child(2) {
    animation: fade 1s ease-in-out 0.33s infinite;
   }
   
   .spinner14 span:nth-child(3) {
    animation: fade 1s ease-in-out 0.66s infinite;
   }
   
   @keyframes fade {
    0%, 100% {
     opacity: 1;
    }
   
    60% {
     opacity: 0;
    }
   }
   
   #boxed .window {
    /* display: none; */
    left: 37%;
    position: fixed;
    top: 90px !important;
    z-index: 99999;
    width: 450px;
}   
.closed {
    display: block;
    height: 30px;
    margin: 0 auto;
    /* opacity: .75; */
    overflow: hidden;
    position: absolute;
    right: -18px;
    text-indent: 0;
    top: -10px;
    width: 30px;
    cursor: pointer;
}
.content_bxmain {
    height: 100%;
    background-color: #fff;
}
.content_left {
    width: 100%;
    float: left;
}
.WatchThisTrip__alarm__fc {
    display: inline-block;
    position: absolute;
    width: 74px;
    background-size: cover;
    /* height: 229px; */
    margin-bottom: 231px;
    bottom: 26px;
    left: calc(50% - 31px);
}
.content_right {
    width: 100%;
    float: left;
    background: white;
}
.count-content-list {
    padding: 6px 15px 0;
    color: #3b3b3b;
}
.type-tripclass {
    font-size: 18px;
    margin-bottom: 15px;
    text-align: left;
    margin-top: -42px;
}
.timeoffer {
    margin-bottom: 0;
    font-size: 15px;
}
.price_ {
    color: #5948db;
    font-weight: bold;
    font-size: 18px;
}
.relative {
    position: relative;
}
.section-itenerary {
    min-height: 50px;
    line-height: 1.8em;
}
.section-itenerary .icon-trip-type {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 0 5px;
}
.section-itenerary .icon-trip-type img {
    width: 70px;
}
.section-itenerary .city-code2 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: #404040;
    line-height: 20px;
}
.city-code2.right {
    float: right;
    text-align: right;
}
.section-itenerary .city-code2 b {
    font-size: 20px;
    display: block;
}
.callnow-btn a {
    background: #211a53;
    color: #fff;
    padding: 3px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 23px;
    text-decoration: none;
    max-width: 259px;
    width: 100%;
    margin: 0 auto;
    font-weight: bold;
}
.callnow-btn a img {
    border-radius: 4px;
}
.txt-disclaimer {
    color: #000;
    text-align: center;
    font-size: 12px;
    padding: 10px 0;
}
#mask {
    position: fixed !important;
    left: 0;
    top: 0;
    background: #b9b9b5a8;
    /* display: none; */
    height: 100% !important;
    width: 100% !important;
    z-index: 999;
}
.jsdhr{
    width: 100%;
}
.callnow-btn img {
    animation-name: trin;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    filter: brightness(0) invert(1);
}

@media only screen and (max-width: 600px){
#boxed .window {
    left: 50%;
    width: 329px;
    transform: translate(-50%);
}
.timeoffer {
    font-size: 10px;
}
.WatchThisTrip__alarm__fc {
    bottom: 6px;
    left: calc(50% - 27px);
    font-size: 13px;
}
}